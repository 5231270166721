<template>
  <label :class="classes">
    <slot></slot>
  </label>
</template>

<script>
export default {
  props: ["labelClass"],
  computed: {
    classes() {
      if (!this.labelClass) return "text-secondary";
      return this.labelClass;
    },
  },
};
</script>
